import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { NavLink } from "@remix-run/react";
import { classNames, DEX_COMPONENT_ADDRESS, getIconUrl, getQuoteToken, onIconError, prettyValue, roundALR } from "~/lib/util";
import PopoverAPY from "./popover-apy";
import { useEffect, useState } from "react";

const doneTypingInterval = 500;

let timeOutId: any = undefined;

export function PairsTable({ pairs }) {
  const [displayPairs, setDisplayPairs] = useState(pairs);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const regExSearch = new RegExp(searchTerm, "i");

    // Reset search and display all tokens
    if (!searchTerm) {
      setDisplayPairs(pairs);
      return;
    }

    // Filter tokens by search term
    const filteredPairs = [];
    for (const pair of pairs) {
      const token = pair.Token;

      if (token.symbol && token.symbol.match(regExSearch)) {
        filteredPairs.push(pair);
        continue;
      }

      if (token.name && token.name.match(regExSearch)) {
        filteredPairs.push(pair);
        continue;
      }

      // if (token.address && token.address.match(regExSearch)) {
      //   filteredPairs.push(pair);
      //   continue;
      // }
    }

    setDisplayPairs(filteredPairs);
  }, [searchTerm]);

  function onSearch(value: string) {
    clearTimeout(timeOutId);

    timeOutId = setTimeout(() => {
      setSearchTerm(value);
    }, doneTypingInterval);
  }

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="dark:text-primary-100 text-primary-800">
        <tr>
          <th scope="col" className="pl-0 pr-3 text-left text-sm font-semibold lg:py-3.5 lg:pl-4">
            Pairs
          </th>
          <th colSpan={2} scope="col" className="hidden px-3 pr-1 text-left text-sm font-semibold lg:table-cell lg:py-3.5">
            TVL
          </th>
          <th scope="col" className="hidden px-3 text-left text-sm font-semibold lg:table-cell lg:py-3.5">
            <PopoverAPY title="7D ALR" timespan="This ALR is based on the data of the last seven days." />
          </th>
          <th scope="col" className="hidden px-3 text-left text-sm font-semibold lg:table-cell lg:py-3.5">
            <PopoverAPY title="ALR" timespan="This ALR is based on the data since the creation of the pair." />
          </th>
          <th scope="col" className="relative pl-3 pr-0 lg:py-3.5 lg:pr-4"></th>
        </tr>
      </thead>
      <tbody className="lg:divide-primary-200 lg:divide-y">
        <tr>
          <td colSpan={6}>
            <div className="flex items-center">
              <MagnifyingGlassIcon className="pointer-events-none mx-2 h-5 w-5 text-black/40" aria-hidden="true" />
              <input
                type="search"
                defaultValue={searchTerm}
                onChange={(evt) => onSearch(evt.target.value)}
                autoCorrect="false"
                onClick={(evt) => evt.stopPropagation()}
                onFocus={(evt) => evt.stopPropagation()}
                placeholder="Search..."
                className="h-10 w-full border-none bg-transparent pr-3 placeholder-neutral-400"
              />
            </div>
          </td>
        </tr>
        {displayPairs.map((pair) => {
          const quoteToken = getQuoteToken(pair.Pair.dexAddress);

          return (
            <>
              <tr key={pair.Token.address} className={classNames("dark:hover:bg-primary-800 hover:bg-neutral-100")}>
                <td className="w-full max-w-0 py-4 pl-0 pr-3 font-bold max-sm:pb-2 sm:w-auto sm:max-w-none sm:pl-0 lg:pl-4">
                  <div className="flex items-center">
                    <div className="relative h-8 w-12">
                      <img className="absolute left-4 top-0 h-8 w-8 shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl)} onError={(evt) => onIconError(evt)} alt="" />
                      <img className="absolute left-0 top-0 h-8 w-8 shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl)} onError={(evt) => onIconError(evt)} alt="" />
                    </div>
                    <div className="ml-2 grow lg:ml-4">
                      <span>
                        {pair.Token.symbol} / {quoteToken.symbol}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="dark:text-primary-200 hidden px-3 py-4 pr-1 text-sm font-medium text-black md:table-cell">
                  <p className="flex items-center space-x-2">
                    <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl, 16)} onError={(evt) => onIconError(evt)} alt="" />
                    <span>
                      {prettyValue(pair.Pair.baseTVL || 0)} {pair.Token.symbol}
                    </span>
                  </p>
                  <p className="flex items-center space-x-2">
                    <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl, 16)} alt="" />
                    <span>
                      {prettyValue(pair.Pair.quoteTVL || 0)} {quoteToken.symbol}
                    </span>
                  </p>
                </td>
                <td className="dark:text-primary-200 hidden px-3 py-4 pl-1 font-medium text-black max-sm:pb-2 md:table-cell">${prettyValue(pair.Pair.tvlUSD || 0)}</td>
                <td className="dark:text-primary-200 hidden px-3 py-4 pr-1 text-sm font-medium text-black md:table-cell">
                  <p className="flex items-center space-x-2">
                    <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl, 16)} onError={(evt) => onIconError(evt)} alt="" />
                    <span>{roundALR(pair.Pair.baseAPY7D)}%</span>
                  </p>
                  <p className="flex items-center space-x-2">
                    <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl, 16)} alt="" />
                    <span>{roundALR(pair.Pair.quoteAPY7D)}%</span>
                  </p>
                </td>
                <td className="dark:text-primary-200 hidden px-3 py-4 pr-1 text-sm font-medium text-black md:table-cell">
                  <p className="flex space-x-2">
                    <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl, 16)} onError={(evt) => onIconError(evt)} alt="" />
                    <span>{roundALR(pair.Pair.baseAPY)}%</span>
                  </p>
                  <p className="flex space-x-2">
                    <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl, 16)} alt="" />
                    <span>{roundALR(pair.Pair.quoteAPY)}%</span>
                  </p>
                </td>
                <td className="py-4 pl-3 pr-0 text-right text-sm font-medium max-sm:pb-2 sm:pr-0 lg:pr-4">
                  {pair.Pair.dexAddress == DEX_COMPONENT_ADDRESS ? (
                    <NavLink to={`/liquidity/add/${pair.Token.address}?direction=base`} className={({ isActive }) => (isActive ? "btn-white-table" : "btn-primary-table")}>
                      {({ isActive, isPending }) => (
                        <>
                          {isActive ? (
                            <svg className="inline-block size-4" viewBox="2 2 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M10.986 5.456a.608.608 0 0 0-.827 0 .564.564 0 0 0 0 .809l3.233 3.16H5.29a.579.579 0 0 0-.584.572.58.58 0 0 0 .584.572h8.102l-3.233 3.16a.564.564 0 0 0 0 .81c.11.107.257.167.414.167.155 0 .302-.06.413-.168l4.072-3.982a.778.778 0 0 0 0-1.119l-4.072-3.98z"
                                fill="currentColor"
                                fillRule="nonzero"
                              ></path>
                            </svg>
                          ) : (
                            "Add"
                          )}
                        </>
                      )}
                    </NavLink>
                  ) : null}
                  <NavLink to={`/liquidity/remove/${pair.Token.address}/base`} className="btn-primary-table ml-1">
                    {({ isActive, isPending }) => <>{isActive ? "Remove" : "Remove"}</>}
                  </NavLink>
                </td>
              </tr>
              <tr className="border-b-primary-200 border-b md:hidden">
                <td colSpan={2}>
                  <div className="mx-4 my-2 mt-4 flex items-center justify-between">
                    <div>
                      <p className={classNames("flex items-center space-x-2")}>
                        <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl, 16)} onError={(evt) => onIconError(evt)} alt="" />
                        <span>{prettyValue(pair.Pair.baseTVL || 0)}</span>
                      </p>
                      <p className={classNames("flex items-center space-x-2")}>
                        <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl, 16)} alt="" />
                        <span>{prettyValue(pair.Pair.quoteTVL || 0)}</span>
                      </p>
                    </div>
                    <p>${prettyValue(pair.Pair.tvlUSD || 0)}</p>
                    <div>
                      <p className="flex items-center space-x-2">
                        <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl, 16)} onError={(evt) => onIconError(evt)} alt="" />
                        <span>{roundALR(pair.Pair.baseAPY)}% ALR</span>
                      </p>
                      <p className="flex items-center space-x-2">
                        <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl, 16)} alt="" />
                        <span>{roundALR(pair.Pair.quoteAPY)}% ALR</span>
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </>
          );
        })}
      </tbody>
    </table>
  );
}
