import MagnifyingGlassIcon from "@heroicons/react/20/solid/MagnifyingGlassIcon";
import { LoaderFunctionArgs, json } from "@remix-run/cloudflare";
import { Link, NavLink, Outlet, useLoaderData } from "@remix-run/react";
import { useEffect, useState } from "react";
import { DataBlock } from "~/components/data-block";
import { PairsTable } from "~/components/pairs-table";
import PopoverAPY from "~/components/popover-apy";
import { fetchPrices } from "~/lib/astrolescent";
import { setDbFromContext } from "~/lib/db";
import { IGNITION_COMPONENT_ADDRESS, IGNITION_DEX_COMPONENT_ADDRESS } from "~/lib/ignition";
import { getDexes } from "~/lib/models/dex";
import { getPairs } from "~/lib/models/pair";
import { classNames, onIconError, prettyValue, getQuoteToken, getIconUrl, roundALR, QUOTE_TOKEN_RESOURCE_ADDRESS, XRD_RESOURCE_ADDRESS, DEX_COMPONENT_ADDRESS } from "~/lib/util";

type Pair = {
  baseToken: {
    address: string;
    symbol: string;
    name: string;
    icon: string;
  };
  tvl: number;
  apy: number;
};

export async function loader({ context }: LoaderFunctionArgs) {
  setDbFromContext(context);

  const allPairs = await getPairs();
  const pairs = allPairs.filter((p) => p.Pair.dexAddress !== "component_rdx1cq34ta4ssvtyxm3vf0l4eleunt7uz9ha5wyzrc965xqafdueadpy4x");
  const prices = await fetchPrices();
  const totals = await getDexes();

  pairs.sort((a, b) => {
    // console.log(a.Pair.address, tvl[a.Pair.address]?.usdAmount, b.Pair.address, tvl[b.Pair.address]?.usdAmount);

    if (a.Pair.tvlUSD < b.Pair.tvlUSD) {
      return 1;
    } else if (a.Pair.tvlUSD > b.Pair.tvlUSD) {
      return -1;
    }

    return 0;
  });

  // calc volumes
  const volume24H = pairs.reduce(
    (total, p) => total + (p.Pair.baseVolume24H * (prices[p.Token.address]?.tokenPriceUSD || 0) + p.Pair.quoteVolume24H * prices[getQuoteToken(p.Pair.dexAddress).address]?.tokenPriceUSD || 0) / 2,
    0,
  );

  // calc tvl
  const totalTVL = pairs.reduce((total, p) => total + (p.Pair.tvlUSD || 0), 0);

  return json({
    pairs,
    totalTVL,
    volume24H,
    volumeTotal: totals.reduce((total, d) => total + d.totalVolumeUSD, 0),
    feesTotal: totals.reduce((total, d) => total + d.totalFeesUSD, 0),
  });
}

export default function LiquidityAdd() {
  const { pairs, totalTVL, volume24H, volumeTotal, feesTotal } = useLoaderData<typeof loader>();

  return (
    <div className="mx-4 mb-6 xl:container xl:mx-auto">
      <div className="flex items-center justify-between">
        <h1 className="py-4 text-left text-3xl font-bold lg:p-8 lg:text-4xl">Pools</h1>
        <Link to="/liquidity/create" className="btn-white-small">
          Create New Pool
        </Link>
      </div>

      <dl className="mb-4 grid grid-cols-1 gap-4 lg:grid-cols-4">
        <DataBlock title="Total Value Locked" data={`$${prettyValue(totalTVL)}`} />
        <DataBlock title="24H Volume" data={`$${prettyValue(volume24H)}`} />
        <DataBlock title="Total Trade Volume" data={`$${prettyValue(volumeTotal)}`} />
        <DataBlock title="Total Fees" data={`$${prettyValue(feesTotal)}`} />
      </dl>

      <section className="lg:items-top flex flex-col-reverse lg:flex-row lg:space-x-4">
        <div>
          <div className="dark:bg-background-900 mt-4 grow space-y-4 rounded-xl bg-white p-4 lg:mt-0 lg:rounded-2xl lg:p-8 lg:pt-6">
            {/* <PairsTable pairs={pairs} /> */}
            <PairsTable pairs={pairs.filter((p) => p.Pair.dexAddress == DEX_COMPONENT_ADDRESS)} />
          </div>

          <h2 className="mt-6 text-xl font-medium">Ignition Pools</h2>
          <div className="dark:bg-background-900 mt-4 grow space-y-4 rounded-xl bg-white p-4 lg:mt-0 lg:rounded-2xl lg:p-8 lg:pt-6">
            <PairsTable pairs={pairs.filter((p) => p.Pair.dexAddress == IGNITION_DEX_COMPONENT_ADDRESS)} />
          </div>
        </div>

        <Outlet />
      </section>
    </div>
  );
}
